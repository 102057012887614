/* html,
body { */
/* margin: 0px;
  width: 100%;
  height: 100%;
  background-color: #e7ebf0; */
/* } */
html,
body,
#root,
.App {
  background-color: #242424;
}

/* .App {
  display: flex;
  align-items: center;
} */

/* .App { */
/* text-align: center; */
/* background-color: #61dafb; */
/* height: 100vh; */
/* color: #61dafb; */
/* position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px; */
/* } */
/* .PrimaryContainer {
  background-color: white;
  min-height: 100vh;
  max-width: 1200;
  width: 1200;
  margin-top: 0;
  margin-bottom: 100px;
  border-radius: 0px 0px 16px 16px;
  padding-bottom: 16px;
  padding-top: 70px;
  border: 0.5px solid gray;
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.display-equation {
  margin-right: "1rem";
  margin-left: "1rem";
}
